import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import PortfolioContext from '../../context/context';
import ResumeFile from '../../files/Connor_L_Morris_Resume_[EXTERNAL]_(Jun 2020).pdf'

const About = () => {
  const { about } = useContext(PortfolioContext);
  const { img, paragraphOne, paragraphTwo, paragraphThree, paragraphFour, resume } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about">
      <Container>
        <Title title="About Me" />
        <Row className="about-wrapper">
          <Col md={6} sm={12}>
            <Fade bottom duration={1000} delay={600} distance="30px">
              <div className="about-wrapper__image">
                <AboutImg alt="profile picture" filename={img} />
              </div>
            </Fade>
          </Col>
          <Col md={6} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="about-wrapper__info">
                <p className="about-wrapper__info-text">
                  {paragraphOne ||
                    <p>
                      I am an aerospace engineer with experience in software development and applied spaceflight dynamics mathematics.

                      My passion for understanding the nuances of complex systems and ensuring desired behavior allows me to excel in tackling 
                      even the most challenging of problems. 
                      
                      Furthermore, a core tenet of mine is to be open to growth, and therefore, I am always open to new ideas and new ways of 
                      approaching problems.
                    </p>
                  }
                </p>
                <p className="about-wrapper__info-text">
                  {paragraphTwo ||
                    <p>
                      Since 2015, I have worked both unmanned and manned spacecraft and operational systems in missions spaces ranging from 
                      Low Earth orbit to Lunar orbit in the academic, commercial, and public sectors.
                    </p>
                  }
                </p>
                <p className="about-wrapper__info-text">
                  {paragraphThree || 
                    <p>
                      I am currently working on the Artemis Program at the National Aeronautics and Space Administration's Johnson Space Center
                      to return the next man and first woman to the Moon by 2024.
                    </p>
                  }
                </p>
                <p className="about-wrapper__info-text">
                  {paragraphFour || 
                    <p>
                      In addition to my professional experience, I also host the codebases to several of my personal software projects below.
                    </p>
                  }
                </p>
                {resume && (
                  <span className="d-flex mt-3">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="cta-btn cta-btn--resume"
                      href={ResumeFile}
                    >
                      Resume
                    </a>
                  </span>
                )}
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
